
import { onMounted, defineComponent, inject, reactive, ref, toRefs } from "vue";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import { Delete, Edit, View } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import {
  ISysRole,
  SysRoleModel,
  SysRoleResourceModel,
  SysRoleResourceFiler
} from "@/apis/interface/ISysRole";
import {
  ISysResource,
  SysResourceNodeModel
} from "@/apis/interface/ISysResource";

class SysRoleModelOther extends SysRoleModel {
  selected = false as boolean;
}

export default defineComponent({
  name: "List",
  props: {
    terminal: {
      type: String,
      default: "applet"
    }
  },
  components: {},
  setup(props) {
    const { terminal } = toRefs(props);
    const apiSysRole = inject("apiSysRole") as ISysRole;
    const apiSysResource = inject("apiSysResource") as ISysResource;

    let defaultProps = {
      children: "children",
      label: "name",
      key: "id"
    };

    let roleDataList = ref<SysRoleModelOther[]>([]);
    let middleTree = ref();

    let tabBarClick = (r: SysRoleModelOther) => {
      if (r) {
        roleDataList.value.map((item) => {
          item.selected = false;
        });
        r.selected = true;
        bindingFilter.roleId = r.id;
        loadBindingData();
      }
    };

    let loadRoleData = async () => {
      const resp = await apiSysRole.getList(terminal.value);
      const pager = resp as RespBodyList<SysRoleModel>;
      roleDataList.value = pager.object as SysRoleModelOther[];
      console.log("==>", roleDataList);
      tabBarClick(roleDataList.value[0] as SysRoleModelOther);
    };

    const bindingFilter: SysRoleResourceFiler = reactive({
      terminal: terminal.value,
      roleId: null
    });

    let bindingResources = ref<number[]>([]);

    let loadBindingData = async () => {
      const resp = await apiSysRole.getResourceBind(bindingFilter);
      const pager = resp as RespBodyList<SysRoleResourceModel>;
      bindingResources.value = pager.object.map((item) => item.resourceId || 0);
      middleTree.value.setCheckedKeys(bindingResources.value);
      console.log("key>", bindingResources);
    };

    let rightTreeData = ref<SysResourceNodeModel[]>([]);

    let loadTreeData = async () => {
      rightTreeData.value = [];
      const resp = (await apiSysResource.getMenuTree(
        terminal.value
      )) as RespBodyList<SysResourceNodeModel>;
      console.log("tree data >>", resp.object);
      rightTreeData.value = resp.object;
    };

    let onCheckChange = async (model: SysResourceNodeModel, state: boolean) => {
      // let removeList: Array<number> = [];
      // let addList: Array<number> = [];

      // if (state == false) {
      //   //清除子节点选择
      //   // const recurve = (model: SysResourceNodeModel) => {
      //   //   removeList.push(model.id);
      //   //   for (let item of model.children || []) {
      //   //     recurve(item);
      //   //   }
      //   // };
      //   // recurve(model);
      //   for (let item of model.children || []) {
      //     if (item.id) {
      //       removeList.push(item.id);
      //     }
      //   }
      // } else {
      //   //选中所有子节点//选中所有父节点
      //   if (model.pid) {
      //     addList.push(model.pid);
      //   }
      // }

      // let list: Array<number> = middleTree.value
      //   .getCheckedKeys()
      //   .filter((item: number) => removeList.indexOf(item) < 0);

      // let keys = [...addList, ...list];

      bindingResources.value = middleTree.value.getCheckedKeys();
      console.log("=====>>>>>>>>>", bindingResources.value, model, state);
    };

    let clickRemoveBinding = (item: number) => {
      bindingResources.value = bindingResources.value.filter(
        (b: number) => item != b
      );
      middleTree.value.setCheckedKeys(bindingResources.value);
    };

    let onSubmitClick = async () => {
      console.log(
        "submit========>",
        terminal.value,
        bindingFilter.roleId,
        bindingResources.value
      );
      if (bindingFilter.roleId) {
        let result = (await apiSysRole.updateResourceBind(
          terminal.value,
          bindingFilter.roleId,
          bindingResources.value
        )) as RespBody<boolean>;

        ElMessage({
          type: "success",
          message: "成功修改" + result.object + "条"
        });
      }
    };

    onMounted(() => {
      loadTreeData();
      loadRoleData();
    });

    return {
      middleTree,
      defaultProps,
      bindingFilter,
      roleDataList,
      bindingResources,
      rightTreeData,
      tabBarClick,
      loadBindingData,
      onCheckChange,
      clickRemoveBinding,
      onSubmitClick,
      icon: {
        delete: Delete,
        edit: Edit,
        view: View
      }
    };
  }
});
