
import { defineComponent } from "vue";
import AdminPowerManager from "@/views/UserPowerManager/List.vue";

export default defineComponent({
  name: "List",
  components: {
    AdminPowerManager: AdminPowerManager
  }
});
